import Component from "../../../../shared/js/common/component";
import Swiper from 'swiper';
import { Navigation, Controller, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class BusSlider extends Component{
    static readonly componentName: string = "b-bus-slider";
    innerSliderElements: NodeListOf<HTMLElement>;
    outerSliderElement: HTMLElement;
    thumbsSliderElement: HTMLElement;
    outerSwiper: Swiper;
    thumbsSwiper: Swiper;
    sliderType: String;

    init(): void {
        this.outerSliderElement = this.element.querySelector('.outer-swiper');
        this.innerSliderElements = this.element.querySelectorAll('.inner-swiper');
        this.thumbsSliderElement = this.element.querySelector('.thumbs-swiper');
        this.sliderType = this.element.getElementsByClassName('b-bus-slider__wrapper').item(0).getAttribute('data-slider-type');

        this.registerEvents();
        window.onresize = () => {
            this.registerEvents();
        }
    }

    registerEvents() {
        this.initSliders();
    }

    initSliders() {
        Swiper.use([Navigation, Controller, Thumbs]);

        if (this.sliderType == 'brick') {
            this.thumbsSwiper = new Swiper(this.thumbsSliderElement, {
                spaceBetween: 10,
                slidesPerView: 3,
                freeMode: true,
                watchSlidesProgress: true,
                slideToClickedSlide: true,
                breakpoints: {
                    575: {
                        slidesPerView: 10,
                    }
                }
            });

            this.outerSwiper = new Swiper(this.outerSliderElement, {
                slidesPerView: 1,
                thumbs: {
                    swiper: this.thumbsSwiper,
                },
                allowTouchMove: false,
            });
        }

        this.innerSliderElements.forEach(el => {
            new Swiper(el, {
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
            });
        });
    }
}