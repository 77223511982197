import Component from "../../../../../shared/js/common/component";

export default class MainNavigation extends Component {

    static readonly componentName: string = "main-navigation";

    init(): void {
        this.registerEvents()
    }

    registerEvents() {
        window.onscroll = () => {
            this.stickyNavigation();
        }
    }

    stickyNavigation() {
        let scrollTop = window.scrollY;
        let header = document.getElementsByTagName('header').item(0);

        if (!document.body.classList.contains('bookingAppLayout')) {
            if (scrollTop > 0) {
                header.classList.add('sticky')
            } else {
                header.classList.remove('sticky')
            }
        }
    }

}