import Component from '../../../../shared/js/common/component';
import Swiper from 'swiper';
import {Navigation, Controller} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class History extends Component {
    static readonly componentName: string = 'b-history';
    timelineSwiper: Swiper;
    contentSwiper: Swiper;
    swiperButtonPrevDisabled: HTMLElement;
    swiperButtonNextDisabled: HTMLElement;
    progressBarStart: HTMLElement;
    progressBarEnd: HTMLElement;

    init() {
        this.initSliders();
    }

    initSliders() {
        Swiper.use([Navigation, Controller]);
        this.timelineSwiper = new Swiper('.b-history__timeline', {
            spaceBetween: 30,
            slidesPerView: 3,
            centeredSlides: true,
            freeMode: true,
            watchSlidesProgress: true,
            slideToClickedSlide: true,
            on: {
                slideChange: this.updateDisabledProgressBar
            },
            breakpoints: {
                900: {
                    spaceBetween: 80,
                },
                650: {
                    slidesPerView: 3,
                },
                0: {
                    slidesPerView: 1
                }
            }
        })
        this.contentSwiper = new Swiper('.b-history__content', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            spaceBetween: 30,
            centeredSlides: true,
            slidesPerView: 3,
            thumbs: {
                swiper: this.timelineSwiper,
            },
            on: {
                slideChange: this.updateDisabledProgressBar
            },
            breakpoints: {
                900: {
                    spaceBetween: 80,
                },
                650: {
                    slidesPerView: 3,
                },
                0: {
                    slidesPerView: 1,
                }
            }
        })
        this.timelineSwiper.controller.control = this.contentSwiper;
        this.contentSwiper.controller.control = this.timelineSwiper;
        this.updateDisabledProgressBar();
    }

    updateDisabledProgressBar() {
        this.swiperButtonPrevDisabled = document.querySelector('.swiper-button-prev.swiper-button-disabled');
        this.swiperButtonNextDisabled = document.querySelector('.swiper-button-next.swiper-button-disabled');
        this.progressBarStart = document.querySelector('.b-history__start');
        this.progressBarEnd = document.querySelector('.b-history__end');

        if (this.swiperButtonPrevDisabled) {
            this.progressBarStart.classList.add('muted');
        } else {
            this.progressBarStart.classList.remove('muted');
        }
        if (this.swiperButtonNextDisabled) {
            this.progressBarEnd.classList.add('muted');
        } else {
            this.progressBarEnd.classList.remove('muted');
        }
    }
}
