import Component from "../../../../shared/js/common/component";

export default class SocialShareBar extends Component {

    static readonly componentName: string = "socialShareBar";

    shareItem: HTMLElement;

    init(): void {
        this.shareItem = this.element.querySelector('.socialShareBar__share');

        window.onresize = () => {
            this.registerEvents();
        }
    }

    registerEvents() {
        if(window.matchMedia("(max-width: 991px)").matches) {
            this.shareItem.addEventListener('click', () => {
                this.shareItem.classList.toggle('open');

            })
        }
    }

}