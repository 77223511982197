import Component from "../../../../../shared/js/common/component";
import Mmenu from 'mmenu-js/src/mmenu.js';

export default class MobileNavigation extends Component {
    static readonly componentName: string = "mobile-navigation";

    mmenu: Mmenu;

    init(): void {
       this.registerEvents();
    }

    registerEvents() {
        if(window.matchMedia('(max-width: 991px)').matches) {
            this.initMobileNav();

        }

        window.onresize = () => {
            if(window.matchMedia('(max-width: 991px)').matches) {
                this.initMobileNav();
            }
        }
    }

    initMobileNav() {
        let languageSwitch = document.querySelector('.language-switch')?.innerHTML;
        let languageSwitchHtml = '<div class="">'+languageSwitch+'</div>';
        let mobilebutton = document.querySelector('#mobilebutton')
        let phone = document.querySelector('.socialShareBar__item.phone').innerHTML;
        let mail = document.querySelector('.socialShareBar__item.mail').innerHTML;

        /*
        if(window.matchMedia('(max-width: 767px)').matches) {
            let chat = document.getElementsByClassName('socialShareBar__item chat').item(0).innerHTML;
        }
        */

        document.addEventListener(
            "DOMContentLoaded", () => {
                this.mmenu = new Mmenu( "#mobile-navigation", {
                    "navbars": [
                        {
                            position: 'top',
                            content: [
                                'prev',
                                'title',
                                'close'
                            ]
                        },
                        {
                            position: 'bottom',
                            content: [
                                languageSwitchHtml
                            ]
                        },
                        {
                            position: "bottom",
                            content: [
                                phone,
                                //chat,
                                mail
                            ]
                        }
                    ],
                    "offCanvas": {
                        pageSelector: '#siteWrapper',
                        position: 'left-front',
                    },
                    hooks: {
                        'open:before': () => {
                            setTimeout(() => {
                                mobilebutton.classList.add('is-active');
                            }, 100);
                        },
                        'close:before': () => {
                            setTimeout(() => {
                                mobilebutton.classList.remove('is-active');
                            }, 100);
                        }
                    },
                    "extensions": [
                        "theme-white",
                        "pagedim-black"
                    ],
                    /*
                    TODO searchfield
                    "searchfield": {
                        form: {
                            name: 'search-form',
                            method: 'GET',
                            action: $('header .quick-search form').attr('action')
                        },
                        input: {
                            name: 'q'
                        },
                        submit: true
                    }
                     */
                });

                this.navigationEvents();
            }
        )

    }

    navigationEvents() {
        let mmenuApi = this.mmenu.API;
        let mobilebutton = document.querySelector('#mobilebutton');
        let mobileNav = document.querySelector('#mobile-navigation');

        mobilebutton.addEventListener("click", () => {
            if (mmenuApi) {
                mmenuApi.open();
            }
        })


        mobileNav.getElementsByClassName('mm-listview li a:not(.mm-next)').item(0)?.addEventListener("click", () => {
            mmenuApi.close();
        })

        mobileNav.getElementsByTagName('a').item(0).classList.add('needsclick')
    };



}