import Component from "../../../../shared/js/common/component";
import bootstrap from "../../../../../node_modules/bootstrap/js/index.umd.js";

export default class TeaserModule extends Component {
    static readonly componentName: string = "b-teaser-module";

    teaserModuleCollapse: HTMLElement;
    teaserModuleCollapseItems: Element[];

    init(): void {
        this.teaserModuleCollapse = this.element.querySelector('.'+TeaserModule.componentName+'__collapse-outer-wrapper');
        this.teaserModuleCollapseItems = Array.from(this.element.getElementsByClassName(TeaserModule.componentName+'__collapse-content'));

        this.closeCollapseOnOpen();
    }

    closeCollapseOnOpen() {
        const headerHeight = document.querySelector('header')?.offsetHeight ?? 0;

        this.teaserModuleCollapseItems.forEach((element: HTMLElement) => {
            element.addEventListener('show.bs.collapse', event => {
                const openedContents = this.teaserModuleCollapseItems.filter((el: HTMLElement) => el.classList.contains('show'));
                openedContents.forEach((el: HTMLElement) => {
                    new bootstrap.Collapse(el, {});
                });

                window.scrollTo({
                    top: this.teaserModuleCollapse.getBoundingClientRect().top + window.scrollY - headerHeight,
                    behavior: 'smooth'
                });
            });
        });
    }
}