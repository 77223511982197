/**
 * Import libraries -> use global typescript file
 */

/**
 * Import modules
 */
import CommonApplication from './../../../shared/js/application';

// Bricks
import TeaserModule from "./bricks/teaser-module";
import History from "./bricks/history";
import SocialShareBar from "./social-share-bar/social-share-bar";
import Facts from "./bricks/facts";
import MobileNavigation from "./bricks/navigation/mobile-navigation";
import MainNavigation from "./bricks/navigation/main-navigation";
import Userlike from "./userlike/userlike";
import BusSlider from "./bricks/bus-slider";


/**
 * Application class
 */
class Application extends CommonApplication {
    run() {
        super.run();

        TeaserModule.initialize();
        History.initialize();
        SocialShareBar.initialize();
        Facts.initialize();
        MobileNavigation.initialize();
        MainNavigation.initialize();
        Userlike.initialize();
        BusSlider.initialize();
    }
}

export default Application;