import {CountUp} from "countup.js";
import Component from '../../../../shared/js/common/component';


export default class Facts extends Component {
    static readonly componentName: string = 'b-facts';
    targets: NodeListOf<Element>;

    init() {
        this.registerEvents();
    }

    registerEvents() {
        let observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                this.initCountUp();
            }
        }, {
            root: null,
            threshold: window.matchMedia("(max-width: 575px)") ? 0.1 : 0.5,
        })

        observer.observe(this.element);
    }

    initCountUp() {

        let countUpItems = Array.from(this.element.getElementsByClassName('count-up-item'))
        countUpItems.forEach((item: HTMLElement) => {
            let countUpValues = new CountUp(item, +item.dataset.end, {
                startVal: +item.dataset.start,
                separator: '.',
                decimal: ','
            });
            countUpValues.start();
        })

    }

}